
<template>
<form autocomplete="off" class="">
    <is-loading v-if="isLoading" :box="true" />
    <div class="row gx-lg-4 g-3">
        <div class="col-md-4">
            <label for="senatorial_district_id" class="form-label">Senatorial District</label>
            <select @change="senateChange()" v-model="data.senatorial_district_id" 
                name="senatorial_district_id" id="senatorial_district_id" class="form-select">
                <option value="" > All Senates </option>
                <option v-for="(senate, coIndex) in senateOptions" :key="coIndex" 
                :value="senate.id">{{senate.name}} {{ senate.nickname ? `- ${senate.nickname}` : ''  }}</option>
            </select>
        </div>
        <div class="col-md-4">
            <label for="local_government_id" class="form-label">Local government</label>
            <select @change="lgaChange()" v-model="data.local_government_id" 
                name="local_government_id" id="local_government_id" class="form-select">
                <option value="" > All LGA </option>
                <option v-for="(lga, coIndex) in lgaOptions" :key="coIndex" 
                :value="lga.id">{{lga.name}}</option>
            </select>
        </div>
        <div class="col-md-4">
            <label> Ward </label>
            <multiselect 
            mode="single"
            searchable
            valueProp="id"
            trackBy="name" label="name"
            :options="wardOptions" 
            v-model="data.registration_area_id" placeholder="--select ward --"></multiselect>
        </div> 
    </div>
    <div class="row mt-3 justify-content-end">
        <div class="col-12">
            <div class="mt-3 text-end">
            <b-button variant="primary" class="me-3"
                @click.prevent="getList()"
                type="button"> Get List </b-button>

            <b-button variant="dark" 
                @click.prevent="resetForm()"
                type="button"> Reset </b-button>
            </div>
        </div>
    </div>
</form>
</template>
    
<script>
import Multiselect from '@vueform/multiselect'
import IsLoading from '@/components/IsLoading.vue'

export default {
    components: {
        IsLoading,
        Multiselect,
    },
    data() {
        return {
            isLoading: false,
            data: {
                senatorial_district_id: "",
                local_government_id: "",
                registration_area_id: "",
            },
            mirrorData: null,
            senateOptions: [],
            lgaOptions: [],
            wardOptions: [],
        }
    },
    methods: {
        getList() {
            var payload = Object.fromEntries(
                Object.entries(this.data).filter(([_, v]) => !!v )
            );
            this.$emit('startSearch', payload)
        },
        getOptionData(){
            this.isLoading = true;
            this.$http.get(`${this.absoluteUrl()}/api/senatorial-districts`)
                .then((response) => {
                this.isLoading = false
                if(response.data.success){
                    this.senateOptions = response.data.data
                }
            })
        },
        senateChange(initialize = true){
            if(initialize){
                this.data.local_government_id = "";
                this.data.registration_area_id = "";
            }
            this.wardOptions = [];
            this.lgaOptions = [];
            if(!this.data.senatorial_district_id){ return }
            this.isLoading = true;
            this.$http.get(`${this.absoluteUrl()}/api/local-governments?senatorial_district_id=${this.data.senatorial_district_id}`)
                .then((response) => {
                this.isLoading = false
                if(response.data.success){
                    this.lgaOptions = response.data.data
                }
            })
        },
        lgaChange(initialize = true){
            if(initialize){
                this.data.registration_area_id = "";
            }
            this.wardOptions = [];
            if(!this.data.local_government_id){ return }
            this.isLoading = true;
            this.$http.get(`${this.absoluteUrl()}/api/registration-areas?local_government_id=${this.data.local_government_id}`)
                .then((response) => {
                this.isLoading = false
                if(response.data.success){
                    this.wardOptions = response.data.data
                }
            })
        },
        resetForm() {
            this.data = {...this.mirrorData}
            this.$emit('resetFilters')
        },
    },
    created(){
        this.getOptionData()
    },
    mounted(){
        this.mirrorData = JSON.parse(JSON.stringify(this.data))
    },
}
</script>
