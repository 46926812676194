
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Hospitals</h5>
      <div class="row mb-3 gy-2 justify-content-between">
        <div class="col-sm-12 col-md-4 d-flex">
          <div class="dropdown no-arrow me-2">
            <a class="btn btn-light bg-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="bx bx-menu"></i>
            </a>
            <ul class="dropdown-menu">
              <li>
                <button @click.prevent="toggleFilters()" class="dropdown-item" type="button">
                  Toggle Filters
                </button>
              </li>
              <li @click.prevent="exportCourts()">
                <button class="dropdown-item" type="button">
                  Export Excel
                </button>
              </li>
            </ul>
          </div>
          <div class="input-group auto">
            <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
            <router-link class="btn btn-primary" to="/hospitals/create">
            <i class="ri ri-add-fill align-bottom"></i> <span class="d-inline"> Create New </span></router-link>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="input-group mb-0">
            <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <div class="">
        <common-portlet headertitle="Filters"
         :visible="enableFilters" :open="true">
          <div class="loading-viewport">
            <div class="card-body p-lg-3">
              <hospital-filter-form 
              @startSearch="setFilters($event)"
              @resetFilters="initializeAll()" />
            </div>
          </div>
        </common-portlet>
      </div>
      <div class="mb-3">
        <p class="mb-0">Showing {{paginationInfo}} results</p>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="table-responsive mb-0 loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <b-table striped hover ref="selectableTable" :items="pageData.data"
              :fields="columns" responsive="sm"  align="middle">
              <template v-slot:cell(name)="data">
                <div style="min-width:10rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                  {{ data.item.name }}
                </div>
              </template>
              <template v-slot:cell(hp_code)="data">
                <div style="min-width:10rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                  {{ data.item.hp_code }}
                </div>
              </template>
              <template v-slot:cell(ward)="data">
                <div @click="navigateTo(data.item.id)" v-if="data.item.ward"  class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.ward.name }}</span>
                </div>
              </template>
              <template v-slot:cell(lga)="data">
                <div @click="navigateTo(data.item.id)" v-if="data.item.lga"  class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.lga.name }}</span>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <b-pagination v-model="currentPage" :limit="3" class="pagination-rounded"
          :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import _ from 'lodash';
import HospitalFilterForm from '@/components/forms/HospitalFilterForm.vue';
import CommonPortlet from '@/components/portlets/CommonPortlet.vue';

export default {
  name: "hospitals",
  components:{
    IsLoading,
    CommonPortlet,
    HospitalFilterForm
  },
  data() {
    return {
      isLoading: false,
      searchQuery: "",
      enableFilters: false,
      queryFilters: null,
      pageOptions: [10, 25, 50, 100],
      columns: [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "hp_code",
          label: "Code",
        },
        {
          key: "ward",
          label: "Ward",
        },
        {
          key: "lga",
          label: "LGA",
        }
      ],
      selected: []
    }
  },
  watch: {
    defaultType (val) {
      this.fetchItems()
    }
  },
  computed: {
    pageData(){
      return this.$store.state.hospital.pageData
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 25
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
    paginationInfo(){
      if(!this.pageData.total) return '0 - 0 of 0'
      return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
      - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
      ? this.currentPage * this.itemsPerPage : this.pageData.total}
      of ${this.pageData.total}`
    },
  },
  methods: {
    navigateTo(id){
      this.$router.push({path: `/hospitals/${id}`});
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null) {
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.searchQuery){ payload.search = this.searchQuery }
      if(this.queryFilters) { Object.assign(payload, this.queryFilters)}
      this.isLoading = true
      this.$store.dispatch("hospital/fetchHospitals", payload)
      .then((_) => this.isLoading = false )
    },
    toggleFilters(){
      this.enableFilters = !this.enableFilters; 
      this.initializeAll()
    },
    setFilters(data){
      this.queryFilters = data
      this.fetchItems()
    },
    exportCourts() {
      const payload = {
        url: '/hospitals/export-excel',
        filename: 'hospitals_'
      }
      this.$store.dispatch("changeLoaderValue", true)
      this.$store.dispatch('serverExport', payload)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.status != 200){
            this.alertError('Could not generate hospitals');
          }
      })
    },
    initializeAll(){
      this.queryFilters = null;
    }
  },
  created(){
    this.fetchItems()
  },
}

</script>

